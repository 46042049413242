import { Button } from '@mui/material'
import React, { useState, useEffect } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import ENV from '../../../config/config';
import './css/styles.css'

const Header = (props) => {
    const activeClass = {
        height: '200%',
        borderBottom: '7px solid #621132',
    }

    const {
        activeDOI, setActiveDOI,
        activeDON, setActiveDON,
        activeIGI, setActiveIGI,
        activeIGN, setActiveIGN,
        pageManula, setPageManual
    } = props

    const handleClickLink = (href) => (event) => {
        //window.location.replace(href)
        switch (href) {
            case ('/ListaDenominacionesInternacionales'):
                setActiveDOI(true)
                setActiveDON(false)
                setActiveIGI(false)
                setActiveIGN(false)
                setPageManual(9)
                break
            case ('/ListaDenominacionesNacionales'):
                setActiveDOI(false)
                setActiveDON(true)
                setActiveIGI(false)
                setActiveIGN(false)
                setPageManual(2)
                break
            case ('/IndicacionesGeograficasInternacionales'):
                setActiveDOI(false)
                setActiveDON(false)
                setActiveIGI(true)
                setActiveIGN(false)
                setPageManual(13)
                break
            case ('/IndicacionesGeograficasNacionales'):
                setActiveDOI(false)
                setActiveDON(false)
                setActiveIGI(false)
                setActiveIGN(true)
                setPageManual(11)
                break
        }
    }

    return (
        <>
            <nav class="navbar fixed-top navbar-expand-lg" data-bs-theme="dark" style={{ backgroundColor: '#0C231E !important', paddingTop: '0', paddingBottom: '0', zIndex: '995' }}>
                <div class="container-fluid" style={{ backgroundColor: '#0C231E', color: 'white !important', marginTop: '0', marginBottom: '0' }}>
                    <div class='col-9'>
                        <a style={{ position: 'relative', left: '-30%' }} href='https://www.gob.mx'>
                            <img src="https://acervomarcas.impi.gob.mx:8181/marcanet/resources/images/Logo_128x50.svg" width="128" height="48" alt="Página de inicio, Gobierno de México"></img>
                        </a>
                    </div>
                    <div class='col-3'>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span style={{ color: '#FFFFFF' }} class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                <li class="nav-item">
                                    <a href='https://www.gob.mx/tramites' >
                                        <Button sx={{ color: '#fff' }}>Tramites</Button>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href='https://www.gob.mx/gobierno'>
                                        <Button sx={{ color: '#fff' }}>Gobierno</Button>
                                    </a>
                                </li>
                                <li class="nav-item dropdown">
                                    <a href='https://www.gob.mx/busqueda'>
                                        <Button sx={{ color: '#fff' }}><SearchIcon sx={{ color: '#ffffff' }} /></Button>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            { /* MENU MARCANET */}
            <nav class="navbar fixed-top navbar-expand-lg bg-body-tertiary" style={{ marginTop: '48px', backgroundColor: '#f3f3f3 !important', paddingTop: '0', paddingBottom: '0', zIndex: '995' }}>
                <div class="container-fluid" style={{ backgroundColor: '#f3f3f3', color: 'black !important', marginTop: '0', marginBottom: '0' }}>
                    <div class='col-3' style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>IMPI</div>
                    <div class='col-9' style={{ alignContent: 'center', marginLeft: '-100%' }}>
                        <div class='row'>
                            <div class='col-1' style={{ paddingTop: '1rem', paddingBottom: '1rem' }}></div>
                            <div class='col-11'>
                                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent3" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" style={{ marginRight: '-65%' }}>
                                    <span style={{ color: '#FFFFFF !important' }} class="navbar-toggler-icon"></span>
                                </button>
                                <div class="collapse navbar-collapse" id="navbarSupportedContent3" style={{ paddingLeft: '20%' }}>
                                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                        <li class="nav-item dropdown">
                                            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Consulta la disponibilidad de una marca
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li><a class="dropdown-item" href="https://bsqfig.impi.gob.mx:9292/bsqFig/" target='_blank'>Búsqueda por logotipo</a></li>
                                                <li><a class="dropdown-item" href="https://acervomarcas.impi.gob.mx:8181/marcanet/vistas/common/datos/bsqDenominacionCompleto.pgi" target='_self'>Búsqueda por nombre</a></li>
                                                <li><a class="dropdown-item" href="https://acervomarcas.impi.gob.mx:8181/marcanet/vistas/common/datos/bsqMesRecepcion.pgi" target='_self'>De Signos por Mes de Recepción</a></li>
                                            </ul>
                                        </li>
                                        <li class="nav-item dropdown">
                                            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Búsqueda avanzada
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li><a class="dropdown-item" href="https://acervomarcas.impi.gob.mx:8181/marcanet/vistas/common/datos/bsqFoneticaCompleta.pgi">Búsqueda Fonetica</a></li>
                                                <li><a class="dropdown-item" href="https://acervomarcas.impi.gob.mx:8181/marcanet/vistas/common/dashboard/marcanetDashboardBusquedas.pgi">Otras</a></li>
                                                <li>
                                                    <a class="dropdown-item" href="#">
                                                        Listado de Denominaciones de Origen &raquo;
                                                    </a>
                                                    <ul class="dropdown-menu dropdown-submenu">
                                                        <li>
                                                            <a class="dropdown-item" onClick={handleClickLink('/ListaDenominacionesInternacionales')}>DO Internacionales</a>
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item" onClick={handleClickLink('/ListaDenominacionesNacionales')}>DO Nacionales</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a class="dropdown-item">
                                                        Listado de Indicaciones Geográficas &raquo;
                                                    </a>
                                                    <ul class="dropdown-menu dropdown-submenu">
                                                        <li>
                                                            <a class="dropdown-item" onClick={handleClickLink('/IndicacionesGeograficasInternacionales')}>IG Internacionales</a>
                                                        </li>
                                                        <li>
                                                            <a class="dropdown-item" onClick={handleClickLink('/IndicacionesGeograficasNacionales')}>IG Nacionales</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li><a class='dropdown-item' href='https://acervomarcas.impi.gob.mx:8181/marcanet/vistas/common/datos/bsqMarcasNoTradGralCombo.pgi' style={{wordWrap:'break-word'}}>Listado de Solicitudes de <br/>Registro de Marca de Certificación, <br/>Holográfica, Sonora, Olfativa, <br/>Imagen Comercial o Combinación de los Anteriores</a></li>
                                            </ul>
                                        </li>
                                        <li class="nav-item dropdown">
                                            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Seguimiento de marcas
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li><a class="dropdown-item" href="https://acervomarcas.impi.gob.mx:8181/marcanet/vistas/common/datos/bsqExpedienteCompleto.pgi">Búsqueda por expediente</a></li>
                                                <li><a class="dropdown-item" href="https://acervomarcas.impi.gob.mx:8181/marcanet/vistas/common/datos/bsqRegistroCompleto.pgi">Búsqueda por registro</a></li>
                                                <li><a class="dropdown-item" href="https://acervomarcas.impi.gob.mx:8181/marcanet/vistas/common/datos/bsqRegistroIntCompleto.pgi">Búsqueda por registro internacional</a></li>
                                            </ul>
                                        </li>
                                        <li class="nav-item dropdown">
                                            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                Herramientas
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li><a class="dropdown-item" href="https://acervomarcas.impi.gob.mx:8181/marcanet/vistas/common/validador/marcanetProductos.pgi">¿Cómo clasificar <br/>tus productos o <br/>servicios?</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            {/* SUBHEADER */}
            {/* <nav class="navbar fixed-top navbar-expand-lg bg-body-tertiary" data-bs-theme="dark" style={{ marginTop: '95px', backgroundColor: 'transparent !important', paddingTop: '0', paddingBottom: '0', zIndex: '994' }}>
                <div class="container-fluid" style={{ backgroundColor: '#13322B', color: 'white !important', marginTop: '0', marginBottom: '0' }}>
                    <div class='col-7' style={{ paddingTop: '1rem', paddingBottom: '1rem', opacity: '0' }}>
                        <img style={{ position: 'relative', left: '-24%' }} src="https://siga.impi.gob.mx/assets/images/logoIMPI.png" width="128" height="48" alt="Logotipo IMPI" />
                    </div>
                    <div class='col-5' style={{ alignContent: 'center' }}>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent2" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" style={{ marginRight: '-40%' }}>
                            <span style={{ color: '#FFFFFF !important' }} class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent2" style={{ paddingLeft: '7%' }}>
                            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                <li class="nav-item">
                                    <div style={activeDOI ? activeClass : null}>
                                        <a style={{ color: '#FFFFFF', marginLeft: '15px' }} class={activeDOI ? 'active' : null} onClick={handleClickLink('/ListaDenominacionesInternacionales')}>DO Internacionales</a>
                                    </div>
                                </li>
                                <li class="nav-item">
                                    <div style={activeDON ? activeClass : null}>
                                        <a style={{ color: '#FFFFFF', marginLeft: '15px' }} class={activeDON ? 'active' : null} onClick={handleClickLink('/ListaDenominacionesNacionales')} >DO Nacionales</a>
                                    </div>
                                </li>
                                <li class="nav-item">
                                    <div style={activeIGI ? activeClass : null}>
                                        <a style={{ color: '#FFFFFF', marginLeft: '15px' }} class={activeIGI ? 'active' : null} onClick={handleClickLink('/IndicacionesGeograficasInternacionales')} >IG Internacionales</a>
                                    </div>
                                </li>
                                <li class="nav-item">
                                    <div style={activeIGN ? activeClass : null}>
                                        <a style={{ color: '#FFFFFF', marginLeft: '15px' }} class={activeIGN ? 'active' : null} onClick={handleClickLink('/IndicacionesGeograficasNacionales')} >IG Nacionales</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav> */}
        </>
    )
}

export default Header