import iconXls from './assets/icon-xls.png'
import iconPdf from './assets/icon-pdf.png'
import { Button } from '@mui/material'
import CookieService from '../../../services/cookie.service'
import DataService from '../../../services/data.service'
import { ConstructionOutlined } from '@mui/icons-material'

const ExportButtons = (props) => {

    const { showExportOpt, rowsCount, rowSelectionModel, type, totalRecords } = props

    const exportPDF = async () => {
        const haveToken = await CookieService.createTokenCookie()
        if (haveToken) {
            const token = await CookieService.getCookie()
            return await DataService.exportPDF(rowSelectionModel, type, token, totalRecords)
        }
        else {
            console.log('Fallo el request')
        }
    }

    const exportXlsx = async () => {
        await CookieService.createTokenCookie()
        const token = await CookieService.getCookie()
        return await DataService.exportXlsx(rowSelectionModel, type, token, totalRecords)
    }

    return (
        <>
            {rowSelectionModel.length > 1000 ?
                <div style={{ zIndex: '999', padding: '10px', position: 'fixed', bottom: '0', right: '10rem', display: showExportOpt ? 'block' : 'none' }}>
                    <div class="alert alert-warning" role="alert">
                        Solo se pueden exportar 1000 registros por favor optimice su selección.
                    </div>
                </div>
                :
                <div style={{ zIndex: '999', backgroundColor: '#444', borderRadius: '15px 15px 0px 0px', padding: '10px', position: 'fixed', bottom: '0', right: '10rem', display: showExportOpt ? 'block' : 'none' }}>
                    <label style={{ color: '#fff' }}>Has seleccionado: {rowsCount} registros </label><br />
                    <Button variant="contained" sx={{ marginRight: '15px', backgroundColor: 'transparent', boxShadow: 'none' }} onClick={exportPDF}>
                        <img src={iconPdf} width='30px' height='30px' />
                    </Button>
                    <Button variant="contained" onClick={exportXlsx} sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                        <img src={iconXls} width='30px' height='30px' />
                    </Button>
                </div>}
        </>
    )
}
export default ExportButtons