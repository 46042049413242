import { createBrowserRouter } from 'react-router-dom'
import ListaDenominacionesInternacionales from '../components/molecules/listaDenominacionesInternacionales/listaDenominacionesInternacionales.component'
import ListaDenominacionesNacionales from '../components/molecules/listaDenominacionesNacionales/listaDenominacionesNacionales.component';
import IndicacionesGeograficasInternacionales from '../components/molecules/IndicacionesGeograficasIternacionales/indicacionesInternacionales.component';
import IndicacionesGeograficasNacionales from '../components/molecules/IndicacionesGeograficasNacionales/indicacionesNacionales.component';

const columns = [
  {
    title:'Nombre',
    fieldName: 'firstName'
  },
  {
    title:'apellidos',
    fieldName: 'lastName'
  },
  {
    title:'edad',
    fieldName: 'age'
  }
  ,
  {
    title:'position',
    fieldName: 'id'
  }
];
const router = createBrowserRouter([
    {
        path: "/",
        element: <ListaDenominacionesInternacionales columns={columns}/>
    },
    {
        path: "/ListaDenominacionesInternacionales",
        element: <ListaDenominacionesInternacionales columns={columns}/>
    },
    {
        path: "/ListaDenominacionesNacionales",
        element: <ListaDenominacionesNacionales columns={columns}/>
    },
    {
        path: "/IndicacionesGeograficasInternacionales",
        element: <IndicacionesGeograficasInternacionales columns={columns}/>
    },
    {
        path: "/IndicacionesGeograficasNacionales",
        element: <IndicacionesGeograficasNacionales columns={columns}/>
    },
]);

export default router