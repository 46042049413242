import loadingBg from './assets/loading-bg.png'
import impiGif from './assets/loading-impi.gif'

const Loading = (props) => {
    return (
        <>
        <img src={impiGif} style={{ zIndex:'1000', position:'absolute', top:'30%', left:'33%'}}></img>
        <div 
            style={{ 
                backgroundImage: `url('${loadingBg}')`,
                backgroundSize: 'cover',
                position: 'fixed',
                width: '100%', height: '100%',
                backgroundColor: '#FFFFFF',
                top: '0',
                left: '0',
                right:'0',
                alignContent:'center',
                verticalAlign:'center',
                opacity:.5,
                zIndex:'999',
                overflowY:'hidden'
            }}>
        </div>
        </>
    )
}

export default Loading