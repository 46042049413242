import { Fab, Box, Drawer, Tooltip } from "@mui/material"
import MenuBookIcon from '@mui/icons-material/MenuBook'
import { useState, useEffect } from "react"
import PDFViewer from "../../atoms/PDFViewer/pdfViewer.component"
import DataService from "../../../services/data.service"

const FloatingManual = (props) => {

    const { page } = props 

    const [buttonPostion, setButtonPositoion] = useState('absolute')
    const [buttonBottom, setButtonBottom] = useState('16px')
    const [open, setOpen] = useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const handleScroll = (e) => {
        let btn = document.getElementById('float-manual')
        let factorFix, factorAbs, factor
        if(window.innerHeight <= 730){
            factorFix = .05
            factorAbs = .15
            factor = 1
        }
        else if(window.innerHeight <= 1000){
            factorFix = .10
            factorAbs = -0.1
            factor = .20
        }
        if(window.scrollY < (140*factor)){
          setButtonBottom(`${window.innerHeight*factorFix}px`)
          setButtonPositoion('fixed')
        }
        else{ 
            setButtonBottom(`-${window.innerHeight*factorAbs}px`)
            setButtonPositoion('absolute')
        }
    }
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    }, [])
 
    const DrawerList = (
        <Box sx={{ width:'50rem', height:'100%', position:'inherit', bottom:'0' }} role="presentation"  onClick={toggleDrawer(false)}>
            <PDFViewer path={DataService.getManual(page)}/>
        </Box>
    )

    return(
    <>  
        <Drawer open={open} onClose={toggleDrawer(false)}>
            {DrawerList}
        </Drawer>
        <div id="float-manual" style={{
            position: buttonPostion,
            float: 'left',
            bottom: buttonBottom,
            left:16,
        }}>
            <Fab onClick={toggleDrawer(true)} sx={{bgcolor: '#621132', ':hover':{bgcolor: '#621132'}}}>
                <Tooltip title='Ver manual'>
                    <MenuBookIcon sx={{color:'#ffffff'}}/>
                </Tooltip>
            </Fab>
        </div>
    </>
    )
}

export default FloatingManual