import { Breadcrumbs, Link, Typography } from "@mui/material"
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const BreadCrumbsCustom = (props)=> {
    const { page, url } = props
    const breadcrumbs = [
        <Link underline="hover" color="inherit" href="https://acervomarcas.impi.gob.mx:8181/marcanet/vistas/common/home.pgi" sx={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
            <HomeIcon/>
        </Link>,
        <Typography key="3" color="text.primary" style={{fontWeight:'bold'}}>
          {page}
        </Typography>,
      ];

    return(
        <div style={{display:'block', marginTop:'1em', marginBottom:'1em'}}>
            <Breadcrumbs separator={(<NavigateNextIcon/>)}>
                {breadcrumbs}
            </Breadcrumbs>
        </div>
    )
}
export default BreadCrumbsCustom