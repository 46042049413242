import { Grid } from '@mui/material'
import React from 'react'
import './footer.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';

const Footer = (props) => {
    return (
        <div style={{backgroundColor:'#0C231E', width:'100%',  position:'inherit',left: '0', bottom: '0'}} className=''>
            <Grid container spacing={4} sx={{color:'#ffffff',paddingBottom:'5em'}}>
                <Grid item xs={1}></Grid>
                <Grid item xs={2}>
                    <div style={{alignContent:'left'}}>
                        <img src="https://acervomarcas.impi.gob.mx:8181/marcanet/resources/images/Logo_128x50.svg"  style={{maxWidth: '90%'}}/>
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <div style={{textAlign:'justify'}}>
                        <h3>Enlaces</h3>
                        <a className="footer-link" href="https://participa.gob.mx" target='_blank' rel='noopener'>Participa</a><br/>
                        <a className="footer-link" href="https://www.gob.mx/publicaciones" target='_blank' rel='noopener'>Publicaciones Oficiales</a><br/>
                        <a className="footer-link" href="http://www.ordenjuridico.gob.mx" target='_blank' rel='noopener'>Marco Jurídico</a><br/>
                        <a className="footer-link" href="https://consultapublicamx.inai.org.mx/vut-web/" target='_blank' rel='noopener'>Plataforma Nacional de Transparencia</a><br/>
                        <a className="footer-link" href="https://alertadores.funcionpublica.gob.mx/" target='_blank' rel='noopener'>Alerta</a><br/>
                        <a className="footer-link" href="https://sidec.funcionpublica.gob.mx" target='_blank' rel='noopener'>Denuncia</a><br/>
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <div style={{textAlign:'justify'}}>
                        <h3>¿Qué es gob.mx?</h3>
                        <p>Es el portal único de trámites, información y participación ciudadana. <a className="footer-link" href="https://www.gob.mx/que-es-gobmx">Leer más</a></p>
                        <a className="footer-link" href="https://datos.gob.mx">Portal de datos abiertos</a><br/>
                        <a className="footer-link" href="https://www.gob.mx/accesibilidad">Declaración de accesibilidad</a><br/>
                        <a className="footer-link" href="https://www.gob.mx/privacidadintegral">Aviso de privacidad integral</a><br/>
                        <a className="footer-link" href="https://www.gob.mx/privacidadsimplificado">Aviso de privacidad simplificado</a><br/>
                        <a className="footer-link" href="https://www.gob.mx/terminos">Términos y Condiciones</a><br/>
                        <a className="footer-link" href="https://www.gob.mx/terminos#medidas-seguridad-informacion">Política de seguridad</a><br/>
                        <a className="footer-link" href="https://www.gob.mx/sitemap">Mapa de sitio</a><br/>
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <div style={{textAlign:'justify'}}>
                        <a className='footer-link' href="https://www.gob.mx/tramites/ficha/presentacion-de-quejas-y-denuncias-en-la-sfp/SFP54">Denuncia contra servidores públicos</a><br/>
                        <h3>Síguenos en</h3>
                        <a class='footer-link' href="https://www.facebook.com/gobmexico">
                            <FacebookIcon/>
                        </a>
                        <a class='footer-link' href="https://twitter.com/GobiernoMX">
                            <XIcon/>
                        </a>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default Footer