// Attributes of objects
    // {
    //   "ndO_ID": 0,
    //   "vdenominacion": "string",
    //   "vpais": "string",
    //   "dfechaprotecion": "string",
    //   "vproducto": "string",
    //   "vbasejuridica": "string",
    //   "vlink": "string",
    //   "vestatus": "string",
    //   "vdoF_APO": "string",
    //   "vdoF_PRO": "string",
    //   "vdingimpi": "string"
    // }
  
const ColumnsDOI = [
    {
      title:'Denominación',
      fieldName: 'vdenominacion',
      type:'string'
    },
    {
      title:'País',
      fieldName: 'vpais',
      type:'string'
    },
    {
      title:'Fecha de inicio de Protección',
      fieldName: 'dfechaprotecion',
      type:'date'
    }
    ,
    {
      title:'Producto',
      fieldName: 'vproducto',
      type:'string'
    },
    {
        title:'Estatus',
        fieldName: 'vestatus',
        type:'string'
    }
];

export default ColumnsDOI