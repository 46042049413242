// Attributes of objects

    // {
    //   "ndO_NACIONAL": 0,
    //   "vdenominacion": "string",
    //   "dfechasolicitud": "string",
    //   "vfolio": "string",
    //   "vcodbarras": "string",
    //   "defchA_OPO": "string",
    //   "vlinK_OPO": "string",
    //   "dfechA_DECLARATORIA": "string",
    //   "vlinK_PRO": "string",
    //   "vestatus": "string",
    //   "vproducto": "string"
    // }
  
  
    const ColumnsDON = [
        {
          title:'Denominación',
          fieldName: 'vdenominacion',
          type:'string'
        },
        {
          title:'Producto',
          fieldName: 'vproducto',
          type:'string'
        },
        {
          title:'Fecha de presentación solicitud',
          fieldName: 'dfechasolicitud',
          type:'date'
        }
        ,
        {
          title:'Fecha de emisión declaratoria',
          fieldName: 'dfechA_DECLARATORIA',
          type:'date'
        },
        {
            title:'Estatus',
            fieldName: 'vestatus',
            type:'string'
        }
    ];
    
    export default ColumnsDON