import React, { useEffect, useState } from "react";
import MapNameProperties from "./config/mapNameProperties.config";
import aguilaFondo from './assets/aguila-fondo.jpg'
import aguilaFondo2 from './assets/aguila-fondo-2.jpg'
import aguilaFondo3 from './assets/aguila-fondo-3.jpg'
import aguilaFondo4 from './assets/aguila-fondo-4.jpg'
import { Grid, Link } from "@mui/material";

const FullDataModal = (props) => {
    const { row, type } = props;

    console.log(row)
    const [validRow, setValidRow] = useState(false)
    const [links, setLinks] = useState([])

    const validateRow = () => {
        return Object.keys(row).length > 0
    }

    useEffect(() => {
        setValidRow(validateRow())
        showLinks(row)
    }, [])

    const showLinks = async (row) => {
        const breakPoint = /http/g
        const linkSplit = row.vlink?.split(breakPoint)
        console.log('links')
        console.log(linkSplit)
        setLinks(linkSplit)
    }

    return (
        validRow ? (
            <div style={{ width: '100%', height: 'auto', alignContent: 'center', margin: 'auto auto' }}>
                <div style={{ marginLeft: '10%', marginRight: '10%', marginTop: '0' }}>
                    <h3>{row.vproducto}</h3>
                </div>
                <div style={{ marginLeft: '10%', marginRight: '10%', marginTop: '1%', marginBottom: '3%', boxShadow: '0 0 15px #000000', paddingLeft: '5%', paddingRight: '5%', paddingTop: '1%', paddingBottom: '1%' }}>
                    <Grid container spacing={5} >
                        <Grid item xs={6} sx={{ whiteSpace: 'pre-line' }}>
                            {row.vdenominacion != null && row.vdenominacion != '' ? (
                                <>
                                    <label style={{ fontWeight: 'bold' }}>Denominación:</label><br />
                                    <p>{row.vdenominacion}</p>
                                </>
                            ) : null}
                            {row.vinggeo != null && row.vinggeo != '' ? (
                                <>
                                    <label style={{ fontWeight: 'bold' }}>Indicación geográfica:</label><br />
                                    <p>{row.vinggeo}</p>
                                </>
                            ) : null}
                            {row.vpais != null && row.vpais != '' ? (
                                <>
                                    <label style={{ fontWeight: 'bold' }}>País:</label><br />
                                    <span> {row.vpais} </span><br /><br />
                                </>
                            ) : null}
                            {row.vproducto != null && row.vproducto != '' ? (
                                <>
                                    <div style={{ wordWrap: 'break-word' }}>
                                        <label style={{ fontWeight: 'bold' }}>Producto:</label><br />
                                        <span> {row.vproducto}</span><br /><br />
                                    </div>
                                </>
                            ) : null}
                            {row.dfechaprotecion != null && row.dfechaprotecion != '' ? (
                                <>
                                    <label style={{ fontWeight: 'bold' }}>{type !== 'IGI' ? 'Fecha de Inicio de Protección' : 'Fecha de presentación'}</label><br />
                                    <p>{row.dfechaprotecion}</p>
                                </>
                            ) : null}
                            {row.vdoF_APO != '' && row.vdoF_APO != null ? (
                                <>
                                    <label style={{ fontWeight: 'bold' }}>Datos de Publicación para Oposición (DOF):</label><br />
                                    <p style={{ textAlign: 'justify' }}>{row.vdoF_APO}</p>
                                </>
                            ) : null}
                            {row.vfolio != '' && row.vfolio != null ? (
                                <>
                                    <label style={{ fontWeight: 'bold' }}>Folio de presentación:</label><br />
                                    <p style={{ textAlign: 'justify' }}>{row.vfolio}</p>
                                </>
                            ) : null}
                            {row.vlinK_PRO != null && row.vlinK_PRO != '' ? (
                                <>
                                    <label style={{ fontWeight: 'bold' }}>Publicación en el DOF de la Declaratoria de Protección:</label><br />
                                    <Link href={row.vlinK_PRO} target='_blank' sx={{ wordWrap: 'break-word' }}>{row.vlinK_PRO?.length > 81 ? `${row.vlinK_PRO.slice(0, 81)}
                            ${row.vlinK_PRO.slice(82, row.vlinK_PRO.length)}` : row.vlinK_PRO}</Link><br />
                                </>
                            ) : null}
                            {row.vlink != null && row.vlink != '' ? (
                                <>
                                    <label style={{ fontWeight: 'bold' }}>Link:</label><br />
                                    {links?.map(link => link != '' ? (
                                        <>
                                            <Link href={`http${link}`} target='_blank' sx={{ wordWrap: 'break-word' }}>{`htpp${link}`}</Link><br/><br/>
                                        </>
                                    ) : null)}
                                </>

                            ) : null}
                        </Grid>
                        <Grid item xs={6} sx={{ whiteSpace: 'pre-line' }}>
                            {row.vestatus != null && row.vestatus != '' ? (
                                <>
                                    <label style={{ fontWeight: 'bold' }}>Estatus:</label><br />
                                    <span> {row.vestatus} </span><br /><br />
                                </>
                            ) : null}
                            {row.vbasejuridica != null && row.vbasejuridica != '' ? (
                                <>
                                    <label style={{ fontWeight: 'bold' }}>Base Jurídica:</label><br />
                                    <p style={{ textAlign: 'justify' }}>{row.vbasejuridica}</p>
                                </>
                            ) : null}
                            {row.dfechasolicitud != null && row.dfechasolicitud != '' ? (
                                <>
                                    <label style={{ fontWeight: 'bold' }}>Fecha de presentación solicitud:</label><br />
                                    <p style={{ textAlign: 'justify' }}>{row.dfechasolicitud}</p>
                                </>
                            ) : null}
                            {row.dfechA_DECLARATORIA != null && row.dfechA_DECLARATORIA != '' ? (
                                <>
                                    <label style={{ fontWeight: 'bold' }}>Fecha de emisión de la Declaratoria:</label><br />
                                    <p style={{ textAlign: 'justify' }}>{row.dfechA_DECLARATORIA}</p>
                                </>
                            ) : null}
                            {row.vdoF_PRO != '' && row.vdoF_PRO != null ? (
                                <>
                                    <label style={{ fontWeight: 'bold' }}>Datos de Publicación Declaratoria de Protección (DOF):</label><br />
                                    <p style={{ textAlign: 'justify' }}>{row.vdoF_PRO}</p>
                                </>
                            ) : null}
                            {row.vdetalleingimpi?.vfolio != null || row.vdetalleingimpi?.vcodbarras != null ||
                                row.vdetalleingimpi?.dfecha != null || row.vdetalleingimpi?.dhora != null ? (
                                <>
                                    <label style={{ fontWeight: 'bold' }}>Datos de ingreso al IMPI:</label><br />
                                </>
                            ) : null}
                            {row.vdetalleingimpi?.vfolio != null && row.vdetalleingimpi?.vfolio != '' ? (
                                <>
                                    <label style={{ fontWeight: 'bold' }}>Folio: </label><br /><span>{row.vdetalleingimpi?.vfolio}</span><br />
                                </>
                            ) : null}
                            {row.vdetalleingimpi?.vcodbarras != null && row.vdetalleingimpi?.vcodbarras != '' ? (
                                <>
                                    <label style={{ fontWeight: 'bold' }}>Código de barras: </label><br /><span>{row.vdetalleingimpi?.vcodbarras}</span><br />
                                </>
                            ) : null}
                            {row.vdetalleingimpi?.dfecha != null && row.vdetalleingimpi?.dfecha != '' ? (
                                <>
                                    <label style={{ fontWeight: 'bold' }}>Fecha: </label><br /><span>{row.vdetalleingimpi?.dfecha}</span><br />
                                </>
                            ) : null}
                            {row.vdetalleingimpi?.dhora != null && row.vdetalleingimpi?.dhora != '' ? (
                                <>
                                    <label style={{ fontWeight: 'bold' }}>Hora: </label><br /><span>{row.vdetalleingimpi?.dhora}</span><br />
                                </>
                            ) : null}
                            {row.vcodbarras != null && row.vcodbarras != '' ? (
                                <>
                                    <label style={{ fontWeight: 'bold' }}>Código de barras: </label><br /><span>{row.vcodbarras}</span><br /><br />
                                </>
                            ) : null}
                            {row.defchA_OPO != null && row.defchA_OPO != '' ? (
                                <>
                                    <label style={{ fontWeight: 'bold' }}>Fecha de publicación inicial en el DOF (para oposición): </label><br /><span>{row.defchA_OPO}</span><br />
                                </>
                            ) : null}
                            {row.vlinK_OPO != null && row.vlinK_OPO != '' ? (
                                <>
                                    <br /><label style={{ fontWeight: 'bold' }}>Publicación en el DOF de la Declaratoria de Oposición:</label><br />
                                    <Link href={row.vlinK_OPO} target='_blank' sx={{ wordWrap: 'break-word' }}>{row.vlinK_OPO?.length > 81 ? `${row.vlinK_OPO.slice(0, 81)}
                       ${row.vlinK_OPO.slice(82, row.vlinK_OPO.length)}` : row.vlinK_OPO}</Link>
                                </>
                            ) : null}
                            {row.vlinkvidoc != null && row.vlinkvidoc != '' ? (
                                <>
                                    <br /><label style={{ fontWeight: 'bold' }}>VIDOC:</label><br />
                                    <Link href={row.vlinkvidoc} target='_blank' sx={{ wordWrap: 'break-word' }}>{row.vlinkvidoc?.length > 81 ? `${row.vlinkvidoc.slice(0, 81)}
                            ${row.vlinkvidoc.slice(82, row.vlinkvidoc.length)}` : row.vlinkvidoc}</Link>
                                </>
                            ) : null}

                        </Grid>
                    </Grid>
                </div>
            </div>
        )
            : null
    )
    // <div style={{ paddingLeft: '15%', paddingRight: '15%', paddingTop: '5%', paddingBottom: '13%', backgroundColor: '#ffffff' }}>
    //     {content.map(element => (element))}
    // </div>
}

export default FullDataModal;