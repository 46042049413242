import logo from './logo.svg';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, RouterProvider } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import router from './routes/router'
import Header from './components/atoms/Header/header.component';
import Footer from './components/atoms/Footer/footer.component';
import FloatingManual from './components/molecules/FloatingManual/floatingManual.component';
import SubHeader from './components/molecules/SubHeader/subHeader.component';
import ListaDenominacionesInternacionales from './components/molecules/listaDenominacionesInternacionales/listaDenominacionesInternacionales.component';
import ListaDenominacionesNacionales from './components/molecules/listaDenominacionesNacionales/listaDenominacionesNacionales.component';
import IndicacionesGeograficasInternacionales from './components/molecules/IndicacionesGeograficasIternacionales/indicacionesInternacionales.component';
import IndicacionesGeograficasNacionales from './components/molecules/IndicacionesGeograficasNacionales/indicacionesNacionales.component';
import ScrollButtons from './components/atoms/ScrollButtons/scrollButtons.component';
import ENV from './config/config';
import CookieService from './services/cookie.service';

function App() {

  const hostUrl = ENV === true ? 'http://localhost:5197' : 'https://doig.impi.gob.mx:5001'

  const [value, setValue] = useState('1');
  const [loading, setLoading] = useState(true)
  const [pageManual, setPageManual] = useState(2)
  const [activeDOI, setActiveDOI] = useState(true)
  const [activeDON, setActiveDON] = useState(false)
  const [activeIGI, setActiveIGI] = useState(false)
  const [activeIGN, setActiveIGN] = useState(false)
  
  const handleClickLink = (href) => (event) => {
      //window.location.replace(href)
      switch(href){
          case('/ListaDenominacionesInternacionales'):
              setActiveDOI(true)
              setActiveDON(false)
              setActiveIGI(false)
              setActiveIGI(false)
              setPageManual(9)
          break
          case('/ListaDenominacionesNacionales'):
              setActiveDOI(false)
              setActiveDON(true)
              setActiveIGI(false)
              setActiveIGI(false)
              setPageManual(2)
          break
          case('/IndicacionesGeograficasInternacionales'):
              setActiveDOI(false)
              setActiveDON(false)
              setActiveIGI(true)
              setActiveIGI(false)
              setPageManual(13)
          break
          case('/IndicacionesGeograficasNacionales'):
              setActiveDOI(false)
              setActiveDON(false)
              setActiveIGI(false)
              setActiveIGI(true)
              setPageManual(11)
          break
      }
  }


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleLoading = (value) => {
    setLoading(value)
  }

  let floatBtn = {
    position: 'absolute',
    left: 16,
    bottom: 30
  }

  const handleScroll = (e) => {
    let btn = document.getElementById('float-manual')
    if (window.scrollY > 12) {
      console.log('estoy en el if')
      btn.style.bottom = '0'
    }
    console.log(btn.style.bottom)
    console.log(window.scrollY)
  }

  useEffect(() => {
    const path  = window.location.pathname
    switch(path) {
      case('/DON'):
        setActiveDON(true)
        setActiveDOI(false)
        setActiveIGN(false)
        setActiveIGI(false)
        setPageManual(2)
      break
      case('/DOI'):
        setActiveDON(false)
        setActiveDOI(true)
        setActiveIGN(false)
        setActiveIGI(false)
        setPageManual(9)
      break
      case('/IGN'):
        setActiveDON(false)
        setActiveDOI(false)
        setActiveIGN(true)
        setActiveIGI(false)
        setPageManual(11)
      break
      case('/IGI'):
        setActiveDON(false)
        setActiveDOI(false)
        setActiveIGN(false)
        setActiveIGI(true)
        setPageManual(13)
      break
      default:
        setActiveDON(true)
        setActiveDOI(false)
        setActiveIGN(false)
        setActiveIGI(false)
      break
    }
  }, [])


  return (
    <div onScroll={handleScroll} style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
      <div className="App" style={{ display: 'block', }}  >
        <div style={{ position: 'sticky', top: '0', display: 'block', zIndex: '999' }}>
          <Header 
            activeDOI={activeDOI} setActiveDOI={setActiveDOI} 
            activeDON={activeDON} setActiveDON={setActiveDON}
            activeIGI={activeIGI} setActiveIGI={setActiveIGI}
            activeIGN={activeIGN} setActiveIGN={setActiveIGN}
            pageManual={pageManual} setPageManual={setPageManual}
          />
        </div>
        <Box sx={{ width: '100%', height: '20%', minHeight: '70px', zIndex: '999', typography: 'body1', position: 'inherit' }}>
            {/* <RouterProvider router={router}/> */}
            {
              activeDOI ? (<ListaDenominacionesInternacionales/>): activeDON ? (<ListaDenominacionesNacionales/>) : activeIGI ? (<IndicacionesGeograficasInternacionales/>) : activeIGN ? (<IndicacionesGeograficasNacionales/>) : null
            }
        </Box>
      </div>
      <p style={{ textAlign: 'right', marginRight: '5%', marginTop:'10px', marginBottom:'2rem' }}>v1.0</p>
      <FloatingManual page={pageManual}/>
      <div style={{ display: 'block', marginTop: '2%' }} id='footer' height='30%'>
        <Footer/>
      </div>
    </div>
  )
}

export default App;
