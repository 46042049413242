import axios from "axios"
import ENV from "../config/config"

const hostUrl = ENV ? 'http://localhost:5197':'https://doig.impi.gob.mx:5001'
const CookieService = {
    getCookie : async () =>{
        console.log('obteniento el cookie')
        const name = 'XSRF-TOKEN'
        const cookies = document.cookie.split(';')
        //console.log(cookies)
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim()
          if (cookie.startsWith(name + '=')) {
            //console.log(cookie.substring(name.length +1 ))
            console.log('cookie found')
            return cookie.substring(name.length + 1)
          }
        }
        return null
    },
    createTokenCookie : async () => {
        const result = await axios.get(`${hostUrl}/antiforgery/token`, { withCredentials: true })
        if(result.status === 200){
          return true
        }
        else{
          console.log('there was an error with token request')
          return false
        }
      }
}

export default CookieService