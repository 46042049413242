import React from 'react'
import { useEffect, useState } from 'react'
import {
    Table as MuiTable,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    Table,
    TablePagination,
    Checkbox,
    Button,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Grid,
    TableSortLabel,
    Drawer,
    Tooltip
} from '@mui/material'
import { MoreVert, FilterAlt } from '@mui/icons-material';
import BreadCrumbsCustom from '../../atoms/BreadCrumbs/breadCrumbs.component';
import { orange } from '@mui/material/colors';
import CookieService from '../../../services/cookie.service';
import DataService from '../../../services/data.service';
import ColumnsDOI from './config/ColumnsDOI.config';
import FullDataModal from '../../atoms/FullDataModal/fullDataModal.component';
import Loading from '../../atoms/Loading/Loading.component';
import ExportButtons from '../../atoms/ExportButtons/exportButtons.component';
import DeleteIcon from '@mui/icons-material/Delete';
import { ClearIcon } from '@mui/x-date-pickers';
import aguilaFondo4 from '../../atoms/FullDataModal/assets/aguila-fondo-4.jpg'
import xlsImg from '../../shared/assets/xls.png'

const ListaDenominacionesInternacionales = (props) => {
    const [timeRequest, setTimeRequest] = useState(0)
    const [loading, setLoading] = useState(true)
    const [fullData, setFullData] = useState([])
    const [rows, setRows] = useState([])
    const [selectAll, setSelectAll] = useState(false)
    const [rowSelectionModel, setRowSelectionModel] = useState([])
    const [page, setPage] = useState(0)
    const [pages, setPages] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('age')
    const [showModal, setShowModal] = useState(false)
    const [modalRow, setModalRow] = useState({})
    const [showExportOpt, setShowExportOpt] = useState(false)

    const filterRows = () => async (event) => {
        const filterDenominacion = document.getElementsByName('input-vdenominacion')[0]
        const filterPais = document.getElementsByName('input-vpais')[0]
        const filterFecha = document.getElementsByName('input-dfechaprotecion')[0]
        const filterProducto = document.getElementsByName('input-vproducto')[0]
        const filterEstatus = document.getElementsByName('input-vestatus')[0]

        let filterIconDenominacion = document.getElementById('filter-icon-vdenominacion')
        let filterIconPais = document.getElementById('filter-icon-vpais')
        let filterIconFecha = document.getElementById('filter-icon-dfechaprotecion')
        let filterIconProducto = document.getElementById('filter-icon-vproducto')
        let filterIconEstatus = document.getElementById('filter-icon-vestatus')

        filterIconDenominacion.style.display = filterDenominacion.value === '' ? 'none' : 'flex'
        filterIconPais.style.display = filterPais.value === '' ? 'none' : 'flex'
        filterIconFecha.style.display = filterFecha.value === '' ? 'none' : 'flex'
        filterIconProducto.style.display = filterProducto.value === '' ? 'none' : 'flex'
        filterIconEstatus.style.display = filterEstatus.value === '' ? 'none' : 'flex'

        if (filterDenominacion.value === '' && filterPais.value === '' && filterFecha.value === '' && filterProducto.value === '' && filterEstatus.value === '') {
            setRows(Array.from(new Set([...fullData])))
        }
        else {
            const filteredRows = await fullData.filter(record => {
                if (record.vdenominacion.replace(/ /g, '').toLowerCase().includes(filterDenominacion.value.replace(/ /g, '').toLowerCase().trim())) {
                    return record
                }
            })
                .filter(record => {
                    if (record.vpais.toLowerCase().includes(filterPais.value.toLowerCase())) {
                        return record
                    }
                }).filter(record => {
                    if (String(record.dfechaprotecion).toString().toLowerCase().includes(filterFecha.value.toLowerCase().trim())) {
                        return record
                    }
                }).filter(record => {
                    if (String(record.vproducto).toLocaleLowerCase().includes(filterProducto.value.toLowerCase().trim())) {
                        return record
                    }
                }).filter(record => {
                    if (String(record.vestatus).toLocaleLowerCase().includes(filterEstatus.value.toLowerCase().trim())) {
                        return record
                    }
                })
            console.log(filteredRows)
            setTimeout(() => {
                setRows(Array.from(new Set([...filteredRows])))
                setPage(0)
            }, 1500)
        }

    }

    const handleClick = (fieldName) => (event) => {
        let modal = document.getElementById(`filter-${fieldName}`)
        let input = document.getElementsByName(`input-${fieldName}`)
        modal.style.display = 'block'

        setTimeout(() => {
            input[0].focus()
        }, 150)
    }

    const hideModal = (fieldName) => (event) => {
        setTimeout(() => {
            let modal = document.getElementById(`filter-${fieldName}`)
            modal.style.display = 'none'
        }, 200)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = async (event) => {
        setRowsPerPage(+event.target.value);
        setPages(generatePages(Math.floor(rows.length / (+event.target.value))))
        setPage(0);
    }

    const handleSelectPage = event => {
        setPage(event.target.value)
    }

    const handleSelectAll = (event) => {
        setSelectAll(!selectAll)
        if (!selectAll) {
            setRowSelectionModel(fullData.map(record => record.ndO_ID))
            setShowExportOpt(true)
        }
        else {
            setRowSelectionModel([])
            setShowExportOpt(false)
        }
        setRows(rows.map((row) => {
            if (selectAll) {
                row.selected = !row.selected
            }
            else {
                row.selected = row.selected === false ? !row.selected : true
            }
            return row
        }))
    }

    const handleSelectRow = (row) => async (event) => {
        setSelectAll(row.selected === true && selectAll === true ? false : selectAll)
        row.selected = !row.selected
        const updatedRows = await rows.map((record) => {
            if (record.ndO_ID === row.ndO_ID) {
                record = row
            }
            return record
        })
        setRows(updatedRows)
        if (row.selected === true) {
            rowSelectionModel.push(row.ndO_ID)
            if (rowSelectionModel.length === fullData.length) {
                setSelectAll(true)
            }
            else {
                setSelectAll(false)
            }
            setShowExportOpt(true)
        }
        else {
            const updatedSelection = await rowSelectionModel.filter((record) => {
                if (record !== row.ndO_ID) {
                    return record
                }
            })
            if (updatedSelection.length === fullData.length) {
                setSelectAll(true)
            }
            else {
                setSelectAll(false)
            }
            if (updatedSelection.length === 0) {
                setShowExportOpt(false)
            }
            setRowSelectionModel(updatedSelection)
        }
    }

    const handleSort = (property, type) => (event) => {
        setOrder(order === 'asc' ? 'desc' : 'asc');
        console.log(property)
        console.log(type)
        setOrderBy(property);
        if (order === 'asc') {
            if (type === 'string') {
                rows.sort(function (a, b) {
                    let keyA = String(a[property])
                    let keyB = String(b[property]);
                    // Compare the 2 dates
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0;
                });
            }
            else {
                rows.sort(function (a, b) {
                    const aParts = a[property].split('/')
                    const bParts = b[property].split('/')
                    let keyA = new Date(+aParts[2], aParts[1] - 1, +aParts[0])
                    let keyB = new Date(+bParts[2], bParts[1] - 1, +bParts[0])
                    // Compare the 2 dates
                    if (keyA > keyB) return -1;
                    if (keyA < keyB) return 1;
                    return 0;
                });
            }
        }
        else {
            if (type === 'string') {
                rows.sort(function (a, b) {
                    let keyA = String(a[property])
                    let keyB = String(b[property]);
                    // Compare the 2 dates
                    if (keyA > keyB) return -1;
                    if (keyA < keyB) return 1;
                    return 0;
                });
            }
            else {
                rows.sort(function (a, b) {
                    const aParts = a[property].split('/')
                    const bParts = b[property].split('/')
                    let keyA = new Date(+aParts[2], aParts[1] - 1, +aParts[0])
                    let keyB = new Date(+bParts[2], bParts[1] - 1, +bParts[0])
                    // Compare the 2 dates
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0;
                });
            }
        }
    }

    const toggleDrawer = (anchor, open, row) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setShowModal(open);
        setModalRow(row)
    }

    const generatePages = (num) => {
        let arr = [];
        for (let i = 0; i <= num; i++) {
            arr.push(i);
        }
        return arr;
    }

    const handleClearFilter = (fieldName) => (event) => {
        document.getElementsByName(`input-${fieldName}`)[0].value = ''
        document.getElementById(`filter-icon-${fieldName}`).style.display = 'none'
        setRows(fullData)
    }

    const exportAllRecords = async () => {
        await CookieService.createTokenCookie()
        const token = await CookieService.getCookie()
        return await DataService.exportAllRecords(2, token,)
    }

    useEffect(() => {
        // fetch origin list from enpoint for now just use dummy 
        const getData = async () => {
            //console.log('getData listaDenominacionesInternacionales')
            const result = await CookieService.createTokenCookie()
            if (result) {
                console.log('Existe cookie')
                const cookie = await CookieService.getCookie()
                setTimeout(async () => {
                    if (cookie !== null) {
                        const data = await DataService.getData('/api/Doig/Getdointernacional', cookie)
                        if (data !== null) {
                            setTimeRequest(data.time / 1000)
                            setFullData(data.data)
                            setRows(data.data)
                            setPages(generatePages(Math.floor(data.data.length / rowsPerPage)))
                            setLoading(false)
                        }
                        else {
                            console.log('error en el request de la informacion')
                            setFullData([])
                        }
                    }
                }, 1500);
                console.log('aqui esta tu token:', cookie)
            }
        }
        getData().catch(error => console.log(error))
    }, [])

    return (
        loading ?
            <>
                <Loading />
                <div style={{ height: '700px', minHeight: '700px', width: '100%', clear: 'both', overflowY: 'hidden' }}></div>
            </>
            :
            (
                <div style={{ marginLeft: '5%', marginRight: '13%', alignContent: 'center', paddingTop: '100px' }}>
                    <ExportButtons type={2} showExportOpt={showExportOpt} rowsCount={rowSelectionModel ? rowSelectionModel.length : 0} rowSelectionModel={rowSelectionModel} totalRecords={fullData.length} />
                    <React.Fragment key='right'>
                        <Drawer
                            sx={{
                                '& .MuiPaper-root': {
                                    backgroundImage: `url('${aguilaFondo4}') !important`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center center',
                                },
                                '& .MuiDrawer-paper': {
                                    height: '77% !important',
                                    marginTop: '7% !important',
                                    position: 'absolute'
                                },
                                '& .MuiDrawer-paper': {
                                    width: '70% !important',
                                    marginTop: '8%',
                                    height: '600px',
                                    backgroundColor: 'none !important',
                                    position: 'absolute'
                                },
                                "& .MuiBackdrop-root": {
                                    opacity: '0 !important'
                                },
                            }}
                            variant='temporary'
                            anchor={'right'}
                            open={showModal}
                            onClose={toggleDrawer('right', false, {})}
                        >
                            <FullDataModal row={modalRow} type='DOI' />
                        </Drawer>
                    </React.Fragment>
                    <BreadCrumbsCustom page='Denominaciones de Origen Internacionales' url='/ListaDenominacionesInternacionales' />
                    <div style={{ width: '110%', marginTop: '20px', marginBottom: '5px' }}>
                        <Typography sx={{ textAlign: 'left' }}>
                            Entendemos como denominación de origen, el nombre de una región geográfica del país que sirva para designar un producto originario de la misma, y cuya calidad o características se deban exclusivamente al medio geográfico.
                        </Typography>
                        <div class='row' style={{ marginBottom: '2%' }}>
                            <div class='col-3'>
                                <Button onClick={exportAllRecords} style={{ marginLeft: '-75%' }}><img style={{ width: '4rem', }} src={xlsImg} /></Button>
                            </div>
                            <div class='col-9' style={{ paddingTop: '35px' }}>
                                <Typography sx={{ textAlign: 'right', fontWeight: 'bold' }}>Resultados encontradas: {rows.length}</Typography>
                            </div>
                        </div>
                    </div>
                    <div style={{ maxHeight: '500px', minHeight: '500px', width: '110%', overflowY: 'scroll', position: 'relative' }}>
                        <TableContainer sx={{ width: '100%' }} style={{ overflowX: "initial" }}>
                            <Table>
                                <TableHead sx={{
                                    bgcolor: '#621132 !important',
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 1,
                                }}>
                                    <TableRow>
                                        <TableCell sx={{ width: '30px' }}><Checkbox onChange={handleSelectAll} sx={{ color: '#FFFFFF', '&.Mui-checked': { color: orange[600], width: '5% !important', } }} checked={selectAll} /></TableCell>
                                        {ColumnsDOI.map((column, index) => (
                                            <TableCell style={{ color: '#FFFFFF', alignContent: 'center', verticalAlign: 'middle', width: '20%' }} sortDirection={orderBy === column.fieldName ? order : false}>
                                                <div style={{ display: 'inline-flex', alignContent: 'center', height: '24px', width: '70%' }}>
                                                    <TableSortLabel
                                                        sx={{
                                                            fontSize: '15px',
                                                            fontWeight: 'bold',
                                                            color: '#ffffff',
                                                            ":hover": {
                                                                color: '#FFFFFF !important'
                                                            },
                                                            '&.Mui-active': {
                                                                color: '#ffffff !important',
                                                                fontWeight: 'bold'
                                                            },
                                                            '&.MuiTableSortLabel-root': {
                                                                color: '#ffffff'
                                                            },
                                                            '&.MuiTableSortLabel-iconDirectionDesc': {
                                                                color: '#ffffff !important'
                                                            },
                                                            '& .MuiTableSortLabel-icon': {
                                                                color: 'white !important',
                                                            },

                                                        }}
                                                        active={orderBy === column.fieldName}
                                                        direction={orderBy === column.fieldName ? order : 'asc'}
                                                        onClick={handleSort(column.fieldName, column.type)}
                                                    >
                                                        {column.title}
                                                    </TableSortLabel>
                                                    <div style={{ display: 'none' }} id={`filter-icon-${column.fieldName}`}>
                                                        <FilterAlt style={{ color: '#FFFFFF', position: 'relative' }} />
                                                    </div>
                                                </div>
                                                <div style={{ display: 'inline-flex', alignContent: 'center', marginLeft: '15px', height: '24px', width: '24px', verticalAlign: 'middle' }}>
                                                    <Button onClick={handleClick(column.fieldName)}>
                                                        <MoreVert style={{ color: '#FFFFFF', display: 'flex' }} />
                                                    </Button>
                                                    <div id={`filter-${column.fieldName}`} style={{ display: 'none', position: 'absolute', top: '90%', left: `${(index) * 20}%`, padding: '20px', backgroundColor: '#fff', border: '1px solid #000', borderRadius: '10px', paddingTop: '40px !important', zIndex: '1' }}>
                                                        <TextField
                                                            sx={{
                                                                "& .MuiOutlinedInput-root": {
                                                                    color: "#000",
                                                                    fontFamily: "Arial",
                                                                    fontWeight: "bold",
                                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                                        borderColor: "#621132",
                                                                        borderWidth: "2px",
                                                                    },
                                                                    "&.Mui-focused": {
                                                                        "& .MuiOutlinedInput-notchedOutline": {
                                                                            borderColor: "#621132",
                                                                            borderWidth: "3px",
                                                                        },
                                                                    },
                                                                    "&:hover:not(.Mui-focused)": {
                                                                        "& .MuiOutlinedInput-notchedOutline": {
                                                                            borderColor: "#ccc",
                                                                        },
                                                                    },
                                                                },
                                                                "& .MuiInputLabel-outlined": {
                                                                    color: "#621132",
                                                                    fontWeight: "bold",
                                                                    "&.Mui-focused": {
                                                                        color: "#621132",
                                                                        fontWeight: "bold",
                                                                    },
                                                                },
                                                            }}
                                                            id="filled-basic"
                                                            name={`input-${column.fieldName}`}
                                                            label={`Filtro por ${column.title}`}
                                                            variant="outlined"
                                                            onChange={filterRows()}
                                                            onBlur={hideModal(column.fieldName)}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <ClearIcon onClick={handleClearFilter(column.fieldName)} sx={{ visibility: document.getElementsByName(`input-${column.fieldName}`)[0]?.value ? 'visible' : 'hidden', color: '#d3d3d3', ":hover": { color: 'black' } }} />
                                                                )
                                                            }}
                                                        />

                                                    </div>
                                                </div>

                                            </TableCell>))}
                                    </TableRow>
                                </TableHead>
                                {rows.length > 0 ? (
                                    <TableBody>
                                        {rows
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row) => (
                                                <TableRow sx={{ bgcolor: row.selected || row.ndO_ID === modalRow.ndO_ID ? '#e5d6b9 !important' : '', ":hover": { bgcolor: '#D3D3D3 !important' } }}>
                                                    <TableCell sx={{ bgcolor: row.selected ? '#e5d6b9' : '#D3D3D3', ":hover": { bgcolor: '#D3D3D3 !important' } }}><Checkbox checked={row.selected} onChange={handleSelectRow(row)} sx={{ color: '#D3D3D3', '&.Mui-checked': { color: orange[600] } }} /></TableCell>
                                                    <Tooltip tittle="Ver detalles">
                                                        <TableCell onClick={toggleDrawer('right', true, row)} sx={{ fontWeight: 'bold', textAlign: 'justify' }}><Tooltip title="Ver detalles">{row.vdenominacion}</Tooltip></TableCell>
                                                    </Tooltip>
                                                    <Tooltip tittle="Ver detalles">
                                                        <TableCell onClick={toggleDrawer('right', true, row)} sx={{ textAlign: 'justify' }}><Tooltip title="Ver detalles">{row.vpais}</Tooltip></TableCell>
                                                    </Tooltip>
                                                    <Tooltip tittle="Ver detalles">
                                                        <TableCell onClick={toggleDrawer('right', true, row)} sx={{ textAlign: 'justify' }}><Tooltip title="Ver detalles">{row.dfechaprotecion}</Tooltip></TableCell>
                                                    </Tooltip>
                                                    <Tooltip tittle="Ver detalles">
                                                        <TableCell onClick={toggleDrawer('right', true, row)} sx={{ textAlign: 'justify' }}><Tooltip title="Ver detalles">{row.vproducto}</Tooltip></TableCell>
                                                    </Tooltip>
                                                    <Tooltip tittle="Ver detalles">
                                                        <TableCell onClick={toggleDrawer('right', true, row)} sx={{ textAlign: 'justify' }}><Tooltip title="Ver detalles">{row.vestatus}</Tooltip></TableCell>
                                                    </Tooltip>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        <TableRow sx={{
                                            "& .MuiTableCell-root": {
                                                borderLeft: "0 !important"
                                            }
                                        }}>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell> No se encontraron registros</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </div>
                    <Grid container sx={{ marginTop: '10px' }}>
                        <Grid item xs={9}></Grid>
                        <Grid item xs={2}>
                            {rowSelectionModel.length > 0 ? `${rowSelectionModel.length} elementos seleccionados` : ''}
                        </Grid>
                    </Grid>
                    <div style={{ width: '100%', marginRight: '0%', marginLeft: '15%' }}>
                        <div class='row' style={{ marginTop: '10px' }}>
                            <div class='col-10'>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
                                    component="div"
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    labelRowsPerPage='Elementos por página'
                                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                />
                            </div>
                            <div class='col-2'>
                                <div>
                                    <InputLabel id="pagination-select-label">Página</InputLabel>
                                    <Select
                                        labelId="pagination-select-label"
                                        id="pagination-select"
                                        value={page}
                                        label="Age"
                                        onChange={handleSelectPage}
                                        size='small'
                                    >
                                        {pages.map(pageNum => (
                                            <MenuItem value={pageNum}>{pageNum + 1}</MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
    )
}

export default ListaDenominacionesInternacionales