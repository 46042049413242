import axios from "axios"
import ENV from "../config/config"

axios.interceptors.request.use(function (config) {
    config.timeData = { startTime: new Date()}
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

  axios.interceptors.response.use(function (response) {
    response.config.timeData.endTime = new Date()
    return response;
  }, function (error) {
    return Promise.reject(error);
  });

const hostUrl = ENV === true ? 'http://localhost:5197' : 'https://doig.impi.gob.mx:5001'
const DataService = {
    getData: async (endpoint, token) => {
        try {
            let data = {
                data:[],
                time:0
            }
            const result = await axios.get(`${hostUrl}${endpoint}`, {
                withCredentials: true, headers: {
                    'X-XSRF-TOKEN': token
                }
            })
            if (result.status === 200) {
                data.data = await result.data.map(record => {
                    record.selected = false
                    return record
                })
                data.time = Math.abs( result.config.timeData.startTime - result.config.timeData.endTime )
                return data
            }
            else {
                console.log('there was an error with the request')
                return null
            }
        }
        catch (error) {
            console.log(error)
        }
    },
    getManual: (page) => {
        console.log(`${hostUrl}/api/Doig/GetManualPDF#page=${page}`)
        return `${hostUrl}/api/Doig/GetManualPDF#page=${page}`
    },
    exportPDF: async (selection, type, token, totalRecords) => {
        try {
            const body = {
                "nopt": type,
                "niDs": selection
            }
            const options = {
                method: 'post',
                withCredentials: true,
                headers: { 'X-XSRF-TOKEN': token },
                responseType:'blob',
                url: `${hostUrl}/api/Doig/GeneraNuevoPDF`,
                data: body
            }
            axios(options).then(response=>{
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href= url
                link.setAttribute('download','reporte.pdf')
                document.body.appendChild(link)
                link.click();
          
              });
        }
        catch (error) {
            console.log(error)
        }
    },
    exportXlsx: async (selection, type, token, totalRecords) => {
        try {
            let endpoint = 'GeneraNuevoExcel'
            const body = {
                "nopt": type,
                "niDs": selection,
            }
            const options = {
                method: 'post',
                withCredentials: true,
                headers: { 'X-XSRF-TOKEN': token },
                responseType:'blob',
                url: `${hostUrl}/api/Doig/${endpoint}`,
                data: body
            }
            console.log(options)
            axios( options ).then(response=>{
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href= url
                link.setAttribute('download','reporte.xlsx')
                document.body.appendChild(link)
                link.click();
              });
        }
        catch (error) {
            console.log(error)
        }
    },
    exportAllRecords: async ( type, token ) => {
        try {
            const fileName = type === 1 ? 'IndicacionesGeograficasInternacionales': type === 2 ? 'DenominacionesDeOrigenInternacionales': type === 3 ? 'DenominacionesDeOrigenNacionales' : type === 4 ? 'IndicacionesGeograficasNacionales':'reporte'  
            let endpoint = `ExportToExcel?nopt=${type}`
            const options = {
                method: 'post',
                withCredentials: true,
                headers: { 'X-XSRF-TOKEN': token },
                responseType:'blob',
                url: `${hostUrl}/api/Doig/${endpoint}`,
            }
            axios( options ).then(response=>{
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href= url
                link.setAttribute('download',`${fileName}.xlsx`)
                document.body.appendChild(link)
                link.click();
              });
        }
        catch (error) {
            console.log(error)
        }
    }
}


export default DataService