// Attributes of objects

// {
//     "niG_NACIONAL": 0,
//     "vinggeo": "string",
//     "vproducto": "string",
//     "dfechasolicitud": "string",
//     "vfolio": "string",
//     "vcodbarras": "string",
//     "defchA_OPO": "string",
//     "vlinK_OPO": "string",
//     "dfechA_DECLARATORIA": "string",
//     "vlinK_PRO": "string",
//     "vestatus": "string"
//   }
  
    const ColumnsIGN = [
        {
          title:'Indicación geográfica',
          fieldName: 'vinggeo',
          type:'string'
          
        },
        {
          title:'Producto',
          fieldName: 'vproducto',
          type:'string'
        },
        {
          title:'Fecha de presentación solicitud',
          fieldName: 'dfechasolicitud',
          type:'date'
        }
        ,
        {
          title:'Fecha de emisión declaratoria',
          fieldName: 'dfechA_DECLARATORIA',
          type:'date'
        },
        {
            title:'Estatus',
            fieldName: 'vestatus',
            type:'string'
        }
    ];
    
    export default ColumnsIGN